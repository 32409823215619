<template>
  <div class="login">
    <div class="text-box">
      <div class="title">科技改变金融</div>
      <img
        class="img-ele"
        src="../../assets/img/img_undraw.png"
        alt=""
      >
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
      label-width="0px"
      class="login-form"
    >
      <div class="logo-box">
        <img
          class="logo"
          :src="logoUrl" alt="">
      </div>
      <h3 class="form-title">{{'社区管理系统'}}</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="账号"
        >
          <svg-icon
            slot="prefix"
            icon-class="user"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <p
        class="login-tip"
        v-show="bigChar"
      >大写锁定已打开</p>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          show-password
          @keyup.native="loginKeyDown"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="password"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="googlekey">
        <el-input
          v-model="loginForm.googlekey"
          type="text"
          auto-complete="off"
          placeholder="动态密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="key"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item class="btn-form-item" style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
      <el-form-item
        class="pass-form-item"
        style="width:100%;"

      >
        <span class="" @click="resetPassHandle">重置密码</span>
      </el-form-item>
    </el-form>
    <copy-right :site-name="siteName || 'AlitaTech'"></copy-right>
  </div>
</template>

<script>
  import defaultLogoUrl from '../../assets/img/logo.png'
  import { getSiteInfoNew } from '@/api/data'
  // import { md5 } from '@/utils/md5'
  import md5 from "js-md5";
  import Cookies from "js-cookie";
  import copyRight from '../../components/login/copyRight'
  import { forgetPwd } from '../../api/system/user'

  export default {
    name: "Login",
    components:{
      copyRight,
    },
    data() {
      return {
        md5Pwd: "",
        firstTochar: false,
        bigChar: false,
        loginForm: {
          username: "",
          password: "",
          googlekey: ""
        },
        loginRules: {
          username: [
            { required: true, trigger: "blur", message: "用户名不能为空" }
          ],
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" }
          ],
          googlekey: [
            { required: true, trigger: "blur", message: "动态密码不能为空" }
          ]
        },
        loading: false,
        redirect: undefined,
        logoUrl: '',
        siteName: '',
        defaultLogoUrl
      };
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true
      }
    },
    created(){
      this.getInfo();
    },
    methods: {
      getInfo(){
        getSiteInfoNew({
          domainName:window.location.host,
        }).then((res) => {
          this.logoUrl = res.logo || this.defaultLogoUrl;
          this.siteName = res.siteNameEn;
        })
      },
      handleLogin() {
        this.$refs.loginForm.validate(valid => {
          let pass = this.loginForm.password;
          if (pass !== this.md5Pwd) {
            pass = md5(pass);
          }
          const user = {
            username: this.loginForm.username,
            password: pass,
            googlekey: this.loginForm.googlekey
          };
          if (valid) {
            this.loading = true;
            this.$store.dispatch("Login", user).then(() => {
                this.loading = false;
                this.$router.push({ path: this.redirect || "/" });
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      loginKeyDown(event) {
        const _that = this;
        //是否输入过字母键，且判断是否按键为caps lock
        if (_that.firstTochar) {
          if (event.keyCode === 20) {
            _that.bigChar = !_that.bigChar;
            return;
          }
        }
        //未输入过字母键，或按键不是caps lock，判断每次最后输入的字符的大小写
        var e = event || window.event;
        var keyvalue = e.keyCode ? e.keyCode : e.which;
        var shifKey = e.shiftKey ? e.shiftKey : keyvalue == 16 ? true : false;
        if (typeof _that.loginForm.password === "undefined") {
          return;
        }
        var strlen = _that.loginForm.password.length;
        var password = _that.loginForm.password;

        if (strlen) {
          var uniCode = password.charCodeAt(strlen - 1);
          if (keyvalue >= 65 && keyvalue <= 90) {
            //如果是字母键
            _that.firstTochar = true;
            if (
              (uniCode >= 65 && uniCode <= 90 && !shifKey) ||
              (uniCode >= 97 && uniCode <= 122 && shifKey)
            ) {
              _that.bigChar = true;
            } else {
              _that.bigChar = false;
            }
          }
        }
      },
      resetPassHandle(){
        if(!this.loginForm.username){
          return this.$message({
            message: '请填写账号',
            type: 'warning'
          })
        }
        let domainName = window.location.host
        forgetPwd({username: this.loginForm.username,domainName:'http://'+domainName}).then((res) => {
          this.$message({
            message: '重置密码链接已发送到您的注册邮箱！',
            type: 'success'
          })
        });
      },
    }
  };
</script>
<style>
  .login .login-form .el-input__inner {
    background-color: #FFF;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #E6E6E6;
  }
  .login .login-form .el-button {
    height: 46px;
    border-radius: 23px;
  }
</style>
<style rel="stylesheet/less" lang="less" type="text/less" scoped>
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/bgNew.png");
    background-size: cover;
    height: 100%;
  .text-box {
    width: 772px;
    height: 70%;
    background-color: rgba(254, 254, 255, 0.05);
    border-radius: 10px;
    padding-top: 60px;
    text-align: center;
    margin-right: 150px;
  .title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
  }
  .title,
  .subtitle {
    color: #ffffff;
    text-align: center;
  }
  .subtitle {
    margin-bottom: 40px;
    padding: 0 100px;
  }
  .img-ele {
    height: 60%;
  }
  }
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 380px;
    padding: 0 40px;
    padding-bottom: 40px;
  .login-form .el-input,
  .login-form input {
    height: 38px;
  }
  .login-tip {
    font-size: 12px;
    text-align: center;
    color: red;
  }
  .logo-box {
    text-align: center;
  }
  .form-title {
    font-size: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
  }
  .logo {
    height: 38px;
    max-width: 400px;
    margin: 50px 0 20px 0;
  }
  .btn-form-item {
    margin-top: 40px;
  }
    .pass-form-item {
      text-align: right;
      font-size: 14px;
      color: #999999;
      cursor: pointer;
    }
  }

</style>
