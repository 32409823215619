import { render, staticRenderFns } from "./newLogin.vue?vue&type=template&id=aad13c4c&scoped=true"
import script from "./newLogin.vue?vue&type=script&lang=js"
export * from "./newLogin.vue?vue&type=script&lang=js"
import style0 from "./newLogin.vue?vue&type=style&index=0&id=aad13c4c&prod&lang=css"
import style1 from "./newLogin.vue?vue&type=style&index=1&id=aad13c4c&prod&rel=stylesheet%2Fless&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aad13c4c",
  null
  
)

export default component.exports