<template>
    <div class="copy-right">
      <div class="copy-right-bot">
        Copyright © 2017 - 2020 {{siteName}}. All rights reserved.
      </div>
    </div>
</template>

<script>
  import weiboIcon from '../../assets/img/weibo.png';
  import facebookIcon from '../../assets/img/facebook.png';
  import twitterIcon from '../../assets/img/twitter.png';
  import wechat from '../../assets/img/wechat.png'
  export default {
    name: 'copyRight',
    props: {
      siteName: {

      }
    },
    data() {
      return {
        wechat,
        sameIconList:[
          {
            icon: weiboIcon,
          },
          {
            icon: facebookIcon,
          },
          {
            icon: twitterIcon,
          }
        ]
      }
    }
  }
</script>

<style scoped>
.copy-right {
  color: #A1ABB4;
  font-size: 16px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
}
.copy-right-top {
  display: flex;
  justify-content: center;
  align-items: center;

}
.copy-right-top,
.copy-right-middle {
  margin-bottom: 10px;
}
.top-item {
  margin-right: 10px;
}
  .comp {
    color: #ffffff;
  }
  .qr-small-dailog {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-right-top {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    color: #333333;
  }
  .img-right-bot {
    font-size: 14px;
    color: #333333;
  }
</style>
